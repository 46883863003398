
import Vue, { PropType } from 'vue';

import { EBackgroundType } from '@/domain/edito/types';
import { BaseInteractiveElement } from '@/infrastructure/core/generic/BaseInteractiveElement.interface';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'BaseTile',
  props: {
    content: {
      type: Object as PropType<BaseInteractiveElement>,
      required: true,
    },
    imageSizes: {
      type: String,
      default: '',
    },
    isAlternateLayout: {
      type: Boolean,
      default: false,
    },
    shouldDarkenBackground: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imagePlaceholderId,
    };
  },
  computed: {
    shouldDisplayImage(): boolean {
      return !!this.content?.image && this.content?.backgroundType === EBackgroundType.Image;
    },
    backgroundColor(): string | null {
      let color = null;

      if (this.content?.backgroundColor) {
        color = this.content.backgroundColor;
      }

      return color;
    },
  },
  methods: {
    handleClick(event: Event): void {
      this.$emit('click', event);
    },
  },
});
