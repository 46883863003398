
import Vue from 'vue';
// @ts-ignore
import { AisClearRefinements } from 'vue-instantsearch';

export default Vue.extend({
  name: 'InstantSearchClearRefinements',
  components: {
    AisClearRefinements,
  },
});
