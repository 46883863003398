import { render, staticRenderFns } from "./ModalProductShopReviews.vue?vue&type=template&id=662f8f12"
import script from "./ModalProductShopReviews.vue?vue&type=script&lang=ts"
export * from "./ModalProductShopReviews.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductRatingStars: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductRatingStars.vue').default,BaseButton: require('/home/runner/work/webapp/webapp/components/atoms/button/BaseButton.vue').default,PlaceholderProductReview: require('/home/runner/work/webapp/webapp/components/atoms/placeholder/PlaceholderProductReview.vue').default,BaseModal: require('/home/runner/work/webapp/webapp/components/atoms/modal/BaseModal.vue').default})
