
import Vue from 'vue';
// @ts-ignore
import { AisRangeInput } from 'vue-instantsearch';

type RangeValue = number | string;

export default Vue.extend({
  name: 'InstantSearchRange',
  components: {
    AisRangeInput,
  },
  props: {
    facetName: {
      type: String,
      required: true,
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timerId: null as ReturnType<typeof setTimeout> | null,
      delay: 300,
    };
  },
  beforeDestroy() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
  },
  methods: {
    formatMinValue(minValue: number, minRange: number): RangeValue {
      return minValue && minValue !== minRange ? minValue : '';
    },
    formatMaxValue(maxValue: number, maxRange: number): RangeValue {
      return maxValue && maxValue !== maxRange ? maxValue : '';
    },
    debounceRefine(values: { max: RangeValue; min: RangeValue }, refine: Function): void {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }

      this.timerId = setTimeout(() => {
        refine(values);
      }, this.delay);
    },
  },
});
