
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisPagination } from 'vue-instantsearch';

import { InstantSearchPaginationClassNames } from '@/infrastructure/externalServices/algolia/types';

const localClassNames: Partial<InstantSearchPaginationClassNames> = {
  'ais-Pagination': 'flex flex-row justify-end mt-4',
  'ais-Pagination-item': 'relative flex items-center justify-center size-8 border-b border-b-transparent text-sm hover:border-b-black',
  'ais-Pagination-item--disabled': 'hidden',
  'ais-Pagination-item--firstPage': 'ais-Pagination-item--custom-control',
  'ais-Pagination-item--lastPage': 'ais-Pagination-item--custom-control',
  'ais-Pagination-item--nextPage': 'ais-Pagination-item--custom-control',
  'ais-Pagination-item--previousPage': 'ais-Pagination-item--custom-control',
  'ais-Pagination-item--selected': 'ais-Pagination-item--selected-custom',
  'ais-Pagination-link': 'after:absolute after:top-0 after:left-0 after:block after:size-full',
  'ais-Pagination-list': 'flex gap-2',
};

export default Vue.extend({
  name: 'InstantSearchPagination',
  components: {
    AisPagination,
  },
  props: {
    classNames: {
      type: Object as PropType<Partial<InstantSearchPaginationClassNames>>,
      default: () => ({}),
    },
  },
  computed: {
    customClassNames(): Partial<InstantSearchPaginationClassNames> {
      return {
        ...localClassNames,
        ...this.classNames,
      };
    },
    paddingLinks(): number {
      return this.$device.isMobile ? 1 : 3;
    },
  },
  methods: {
    scrollToTop(): void {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
});
