
import Vue from 'vue';
// @ts-ignore
import { AisRefinementList } from 'vue-instantsearch';

import IcCheck from '@/assets/svg/ic_check.svg?inline';
import { EFacetNameRefinementList } from '@/domain/core/algolia/types';
import { RefinementListItem } from '@/infrastructure/externalServices/algolia/types';

enum EColorID {
  Beige = 59,
  Black = 27,
  Blue = 24,
  Brown = 20,
  Burgundy = 58,
  Golden = 40,
  Gray = 26,
  Green = 23,
  Multicolour = 39,
  Orange = 43,
  Pink = 45,
  Purple = 42,
  Red = 22,
  Silver = 41,
  Transparent = 19,
  White = 21,
  Wooden = 51,
  Yellow = 25,
}

const colorsCssClasses = Object.freeze({
  [EColorID.Beige]: 'bg-[#dac597]',
  [EColorID.Black]: 'bg-[#000000]',
  [EColorID.Blue]: 'bg-[#92c7db]',
  [EColorID.Brown]: 'bg-[#634539]',
  [EColorID.Burgundy]: 'bg-[#880000]',
  [EColorID.Golden]: 'bg-[url("https://images.selency.com/c7ba8e6d-02e2-4b9f-b452-665d946f315a")] bg-cover bg-no-repeat',
  [EColorID.Gray]: 'bg-[#dcdee0]',
  [EColorID.Green]: 'bg-[#60987f]',
  [EColorID.Multicolour]: 'bg-[url("https://images.selency.com/02bdb586-a48c-488a-a83c-a770a6fe5ff7")] bg-cover bg-no-repeat',
  [EColorID.Orange]: 'bg-[#f99234]',
  [EColorID.Pink]: 'bg-[#ffc0cb]',
  [EColorID.Purple]: 'bg-[#8b5177]',
  [EColorID.Red]: 'bg-[#eb2929]',
  [EColorID.Silver]: 'bg-[url("https://images.selency.com/c4c230bc-855d-4141-a623-af7c56125122")] bg-cover bg-no-repeat',
  [EColorID.Transparent]: 'bg-[url("https://images.selency.com/eb9eb554-85f5-422a-91bd-04eae625f417")] bg-cover bg-no-repeat',
  [EColorID.White]: 'bg-white before:absolute before:inset-0 before:rounded-full before:border before:border-gray-darker',
  [EColorID.Wooden]: 'bg-[url("https://images.selency.com/92a59aa6-445a-48d3-abf8-1eec0c5a4d43")] bg-cover bg-no-repeat',
  [EColorID.Yellow]: 'bg-[#fef571]',
});

export default Vue.extend({
  name: 'InstantSearchCheckboxesColor',
  components: {
    AisRefinementList,
    IcCheck,
  },
  props: {
    isCrawlable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      EFacetNameRefinementList,
      sortBy: ['name:asc', 'count:desc'],
    };
  },
  methods: {
    getColorCssClassesFromLabel(label: RefinementListItem['label']): string {
      const { colorId } = this.splitColorFacet(label);
      const fmtId: EColorID = Number.parseInt(colorId, 10);

      return colorsCssClasses?.[fmtId];
    },
    getDisplayValueFromLabel(label: RefinementListItem['label']) {
      const { displayValue } = this.splitColorFacet(label);

      return displayValue;
    },
    splitColorFacet(label: RefinementListItem['label']): { colorId: string; displayValue: string } {
      const [displayValue, colorId] = label?.split('||') || [];

      return {
        displayValue,
        colorId,
      };
    },
  },
});
