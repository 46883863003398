
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisNumericMenu } from 'vue-instantsearch';

import { AisNumericMenuItem } from '@/infrastructure/externalServices/algolia/types';

export default Vue.extend({
  name: 'InstantSearchNumericMenu',
  components: {
    AisNumericMenu,
  },
  props: {
    facetName: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<AisNumericMenuItem[]>,
      required: true,
    },
    isCrawlable: {
      type: Boolean,
      default: false,
    },
  },
});
