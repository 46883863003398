
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisInstantSearchSsr } from 'vue-instantsearch';

import { EFacetNameToggleRefinement, EHitsThreshold } from '@/domain/core/algolia/types';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EProductStatus, LegacyCategory } from '@/domain/product/types';
import { BaseSelectOption } from '@/infrastructure/core/components/input/BaseSelectOption.interface';
import { RadioOption } from '@/infrastructure/core/components/input/Radio.interface';
import { adaptAlgoliaQuery } from '@/infrastructure/externalServices/algolia/adaptAlgoliaQuery';
import { CurrentRefinementList } from '@/infrastructure/externalServices/algolia/types';
import { EGtmEcommerceListName } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import InstantSearchSsr from '@/mixins/instantSearchSsr';

const idfDepartments = new Set(['75', '77', '78', '91', '92', '93', '94', '95']);
const availableDepartments = new Set(['31', '13', '34', '67', '35', '06', '64', '33', '44', '59', '69']);

enum EDepartmentForBS {
  IDF = 'IDF',
  Other = 'OTHER',
}

export default Vue.extend({
  name: 'InstantSearchSSR',
  components: {
    AisInstantSearchSsr,
  },
  mixins: [InstantSearchSsr],
  props: {
    additionalFilters: {
      type: String,
      default: '',
    },
    category: {
      type: Object as PropType<LegacyCategory>,
      default: () => ({} as LegacyCategory),
    },
    isSoldOutFilterActive: {
      type: Boolean,
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
    shouldInsertRandomTextInResults: {
      type: Boolean,
      default: false,
    },
    shouldDisplayFilters: {
      type: Boolean,
      default: false,
    },
    shouldAppendSoldOutItems: {
      type: Boolean,
      default: false,
    },
    trackingId: {
      type: String as PropType<`${EGtmEcommerceListName}`>,
      required: true,
    },
  },
  data() {
    return {
      EHitsThreshold,
      toggleFilters: false,
    };
  },
  computed: {
    algoliaSortIndices(): BaseSelectOption[] | RadioOption[] {
      const { products, productsAscPriceOrder, productsDescPriceOrder, productsNewestOrder } = this.$config.algolia.indices || {};
      const i18nPath = 'page.search.sort';

      const indices = [
        { i18nKey: 'relevance', value: products },
        { i18nKey: 'price.asc', value: productsAscPriceOrder },
        { i18nKey: 'price.desc', value: productsDescPriceOrder },
        { i18nKey: 'newest', value: productsNewestOrder },
      ];

      return indices.map(({ i18nKey, value }) => ({
        label: this.$t(`${i18nPath}.${i18nKey}`),
        value: this.localizeIndex(value),
      }));
    },
    categoryMobileTitle(): string | null {
      return this.$device.isMobile ? this.category?.name : null;
    },
    isBackdropVisible(): boolean {
      return this.$accessor?.ui.backdrop.isVisible;
    },
    isSoldOutFilterActiveModel: {
      get(): boolean {
        return this.isSoldOutFilterActive;
      },
      set(value: boolean): void {
        this.$emit('update:is-sold-out-filter-active', value);
      },
    },
    hasCategoryMerchItems(): boolean {
      return !!this.category?.merchandisingItems?.length;
    },
    hitsPerPage(): number {
      return EHitsThreshold.Max
        - +this.shouldInsertRandomTextInResults
        - +this.hasCategoryMerchItems;
    },
    filters(): string {
      const baseFilter = `${EFacetNameToggleRefinement.Retail}:false`;
      const publishedStatus = `${EFacetNameToggleRefinement.Status}:${EProductStatus.Published}`; // NOTE: default filter.
      let localFilters = `${baseFilter} AND ${publishedStatus}`;

      if (this.additionalFilters) {
        localFilters += ` AND ${this.additionalFilters}`;
      }

      if (this.isSoldOutFilterActiveModel) {
        const soldOutStatus = `${EFacetNameToggleRefinement.Status}:${EProductStatus.SoldOut}`;
        const publishedStatusScore = this.shouldAppendSoldOutItems ? 2 : 1;
        const refinedStatusFilter = `(${soldOutStatus}<score=1> OR ${publishedStatus}<score=${publishedStatusScore}>)`;

        localFilters = localFilters.replace(publishedStatus, refinedStatusFilter);
      }

      return localFilters;
    },
    searchSeoTitle(): string | null {
      return this.$route?.params?.slug?.replace(/-/g, ' ') || null;
    },
    title(): string | null {
      let title = this.categoryMobileTitle;

      if (this.$route?.name === ERouteName.SearchSeo) {
        title = this.searchSeoTitle;
      }

      if (this.$route?.name === ERouteName.LatestFinds) {
        title = this.$t('page.latestFinds.title') as string;
      }

      return title;
    },
  },
  watch: {
    isBackdropVisible(newValue) {
      if (!newValue) {
        this.toggleFilters = false;
      }
    },
  },
  methods: {
    async toggleMobileFilters($eventData: { scrollToTop: boolean }): Promise<void> {
      const { scrollToTop = false } = $eventData || {};

      this.toggleFilters = !this.toggleFilters;

      if (this.toggleFilters) {
        this.$accessor.ui.SHOW_BACKDROP();
      } else {
        this.$accessor.ui.closeDialog();

        if (scrollToTop) {
          await this.$nextTick();

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      }
    },
    localizeIndex(templatedIndexName: string): string {
      return templatedIndexName.replace('{locale}', this.$i18n.locale.toUpperCase());
    },
    updateAlgoliaNbHits(nbHits: number): void {
      this.$emit('update:nb-hits', nbHits);
    },
    emitSaveableQuery(currentRefinements: CurrentRefinementList[]): void {
      const saveableQuery = adaptAlgoliaQuery(currentRefinements, this.filters, this.query);

      this.$emit('update:saveable-query', saveableQuery);
    },
    formatUserDepartment(department: string | null): string {
      if (idfDepartments.has(`${department}`)) {
        return EDepartmentForBS.IDF;
      } else if (availableDepartments.has(`${department}`)) {
        return `D${department}`;
      }

      return EDepartmentForBS.Other;
    },
  },
});
