import { Query } from '@/domain/search/types';
import { CurrentRefinementList } from '@/infrastructure/externalServices/algolia/types';

export const adaptAlgoliaQuery = (currentRefinementList: CurrentRefinementList[], filters: string, query: string): Query => {
  const facetFilters = [];
  const numericFilters = [];

  for (const currentFacet of currentRefinementList) {
    const disjunctiveFacetFilters = [];

    if (currentFacet.refinements) {
      for (const refinement of currentFacet.refinements) {
        if (refinement.type === 'facet') {
          facetFilters.push(`${refinement.attribute}:${refinement.value}`);
        }

        if (refinement.type === 'disjunctive') {
          disjunctiveFacetFilters.push(`${refinement.attribute}:${refinement.value}`);
        }

        if (refinement.type === 'numeric') {
          const { attribute, operator, value } = refinement;

          numericFilters.push(`${attribute}${operator}${value}`);
        }
      }

      if (disjunctiveFacetFilters.length > 0) {
        facetFilters.push(disjunctiveFacetFilters);
      }
    }
  }

  return {
    filters,
    facetFilters,
    numericFilters,
    query,
  };
};
