var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AisRangeInput',{attrs:{"attribute":_vm.facetName},scopedSlots:_vm._u([{key:"default",fn:function({
      currentRefinement,
      range,
      canRefine,
      refine,
    }){return [_c('div',{staticClass:"flex items-end flex-none w-full gap-4 text-tiny"},[_c('InputText',{attrs:{"data-test-id":`min-range-input-${_vm.facetName}`,"input-id":`range_${_vm.facetName}_min`,"is-disabled":!canRefine,"placeholder":`Min (${range.min})`,"suffix":_vm.suffix,"type":"number","value":`${_vm.formatMinValue(currentRefinement.min, range.min)}`},on:{"update:value":function($event){_vm.debounceRefine({
          min: $event,
          max: _vm.formatMaxValue(currentRefinement.max, range.max),
        }, refine)}}}),_vm._v(" "),_c('InputText',{attrs:{"data-test-id":`max-range-input-${_vm.facetName}`,"input-id":`range_${_vm.facetName}_max`,"is-disabled":!canRefine,"placeholder":`Max (${range.max})`,"suffix":_vm.suffix,"type":"number","value":`${_vm.formatMaxValue(currentRefinement.max, range.max)}`},on:{"update:value":function($event){_vm.debounceRefine({
          min: _vm.formatMinValue(currentRefinement.min, range.min),
          max: $event,
        }, refine)}}})],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }