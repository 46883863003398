
import Vue from 'vue';
// @ts-ignore
import { AisConfigure } from 'vue-instantsearch';

import { EHitsThreshold } from '@/domain/core/algolia/types';

export default Vue.extend({
  name: 'InstantSearchConfigure',
  components: {
    AisConfigure,
  },
  props: {
    analytics: {
      type: Boolean,
      default: false,
    },
    clickAnalytics: {
      type: Boolean,
      default: false,
    },
    facets: {
      type: Array,
      default: () => ['*'],
    },
    getRankingInfo: {
      type: Boolean,
      default: false,
    },
    hitsPerPage: {
      type: Number,
      default: EHitsThreshold.Max,
    },
    maxValuesPerFacet: {
      type: Number,
      default: 100,
    },
    optionalFilters: {
      type: Array,
      default: () => ['*'],
    },
    filters: {
      type: String,
      default: '',
    },
    query: {
      type: String,
      default: '',
    },
  },
});
