
import Vue from 'vue';
import { DateTimeFormatOptions } from 'vue-i18n';

import Logo from '@/assets/svg/logo.svg?inline';
import { PaginatedList } from '@/domain/core/api/types';
import { EOrderEntryReviewStatus, Review } from '@/domain/review/types';
import { EGtmViewModal } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

const placeholdersMaxCount = 4;
const starsMaxCount = 5;

export default Vue.extend({
  name: 'ModalProductShopReviews',
  components: {
    Logo,
  },
  props: {
    rating: {
      type: Number,
      default: null,
    },
    reviewsCount: {
      type: Number,
      default: 0,
    },
    shopId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      currentPage: 0,
      EOrderEntryReviewStatus,
      isLoading: false,
      reviews: {
        items: [] as Review[],
        pagination: {},
      } as PaginatedList<Review>,
      starsMaxCount,
    };
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    await this.fetchReviews();
  },
  computed: {
    currentRating(): string | null {
      let label = null;

      if (this.rating) {
        label = `${this.rating}/${starsMaxCount}`;
      }

      return label;
    },
    placeholdersCount(): number {
      let count = placeholdersMaxCount;

      if (this.reviewsCount < placeholdersMaxCount) {
        count = this.reviewsCount;
      }

      return count;
    },
    hasMoreReviewsToLoad(): boolean {
      const { page: currentPage, pages: totalPages } = this.reviews?.pagination || {};

      return currentPage < totalPages;
    },
  },
  mounted() {
    this.$gtm.push({ event: EGtmViewModal.ProductShopReviews });
  },
  methods: {
    closeModal(): void {
      this.$emit('close');
    },
    async fetchReviews(page?: number): Promise<void> {
      try {
        if (!this.shopId) {
          throw new Error('No shop ID provided');
        }

        this.isLoading = true;

        const reviews = await this.$repositories.shop.getShopReviews(this.shopId, page);
        const { items, pagination } = this.formatReviews(reviews);

        this.reviews.items.push(...items);
        this.reviews.pagination = pagination;

        this.currentPage += 1;
      } catch (err) {
        this.$errorMonitor.report(err, 'error');

        throw err;
      } finally {
        this.isLoading = false;
      }
    },
    // TODO: implement a proper timeAgo() function.
    formatDate(date: string): string {
      const fmtDate = new Date(date);
      const options: DateTimeFormatOptions = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      };

      return new Intl.DateTimeFormat(this.$i18n.locale, options).format(fmtDate);
    },
    formatReviews({ items, pagination }: PaginatedList<Review>): PaginatedList<Review> {
      return {
        items: items?.map((review) => ({
          ...review,
          collectedAt: this.formatDate(review.collectedAt),
        })) || [],
        pagination,
      };
    },
  },
});
