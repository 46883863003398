
import Vue from 'vue';

import IcSlidersHorizontal from '@/assets/svg/ic_sliders_horizontal.svg?inline';

export default Vue.extend({
  name: 'ButtonToggleFilters',
  components: {
    IcSlidersHorizontal,
  },
  methods: {
    handleClick(): void {
      this.$emit('click');
    },
  },
});
