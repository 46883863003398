
import Vue, { PropType } from 'vue';

import { InputRangeGroupItem } from '@/infrastructure/core/components/instantsearch/InstantSearch.interface';

export default Vue.extend({
  name: 'InstantSearchRangeGroup',
  props: {
    facets: {
      type: Array as PropType<InputRangeGroupItem[]>,
      required: true,
    },
  },
});
