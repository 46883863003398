
import Vue from 'vue';
import { VueConstructor } from 'vue/types/umd';
// @ts-ignore
import { AisCurrentRefinements } from 'vue-instantsearch';

import IcCrossThick from '@/assets/svg/ic_cross_thick.svg?inline';
import { CurrentRefinementList } from '@/infrastructure/externalServices/algolia/types';
import currentRefinements from '@/mixins/currentRefinements';

export default (Vue as VueConstructor<Vue & InstanceType<typeof currentRefinements>>).extend({
  name: 'InstantSearchCurrentRefinements',
  components: {
    AisCurrentRefinements,
    IcCrossThick,
  },
  mixins: [currentRefinements],
  methods: {
    transformItems(items: CurrentRefinementList[]): CurrentRefinementList[] {
      this.$emit('update:current-refinements', items);

      return items.map((item: CurrentRefinementList) => {
        const refinements = item.refinements?.map((refinement) => {
          const attribute = refinement?.attribute || '';
          const label = refinement?.label || '';

          return {
            ...refinement,
            label: this.$_currentRefinements_getRefinementDisplayValue(attribute, label, refinement?.value || null),
          };
        });

        return {
          ...item,
          refinements,
        };
      });
    },
  },
});
