
import Vue from 'vue';
// @ts-ignore
import { AisToggleRefinement } from 'vue-instantsearch';

export default Vue.extend({
  name: 'InstantSearchToggle',
  components: {
    AisToggleRefinement,
  },
  props: {
    facetName: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    on: {
      type: [String, Number, Boolean],
      default: true,
    },
    off: {
      type: [String, Number, Boolean],
      default: false,
    },
    isCrawlable: {
      type: Boolean,
      default: false,
    },
  },
});
