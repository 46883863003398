var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"relative z-0 flex w-full rounded-sm",class:{
    'bg-accent': _vm.shouldDisplayImage,
  },style:({
    backgroundColor: _vm.backgroundColor,
  })},[(_vm.shouldDisplayImage)?_c('div',{staticClass:"absolute inset-0"},[_c('NuxtImg',{staticClass:"object-cover w-full h-full opacity-90",attrs:{"alt":_vm.content.title,"loading":"lazy","sizes":_vm.imageSizes,"src":_vm.content.image || _vm.imagePlaceholderId}})],1):_vm._e(),_vm._v(" "),(_vm.shouldDarkenBackground)?_c('div',{staticClass:"absolute inset-0 z-10 w-full h-full bg-black/15",attrs:{"data-testid":"base-tile-backdrop"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"z-20 flex flex-col w-full text-white uppercase",class:{
      'items-start justify-end mx-3 mt-auto mb-3 text-xl/5 lg:mb-6 lg:mx-6 lg:text-3xl/8 lg:w-3/4': _vm.isAlternateLayout,
      'items-center justify-center px-2 m-auto text-sm leading-4 text-center': !_vm.isAlternateLayout,
    },attrs:{"data-testid":"base-tile-content"}},[_c('BaseLink',{staticClass:"font-bold",class:{
        'lg:mb-2': _vm.isAlternateLayout,
      },attrs:{"href":_vm.content.url,"is-expanded":""},on:{"click":_vm.handleClick}},[_vm._v("\n      "+_vm._s(_vm.content.title)+"\n    ")]),_vm._v(" "),(_vm.content.cta)?_c('span',{staticClass:"underline",class:{
        'text-base': !_vm.isAlternateLayout,
        'text-xs lg:text-base': _vm.isAlternateLayout,
      },attrs:{"data-testid":"base-tile-cta"}},[_vm._v("\n      "+_vm._s(_vm.content.cta)+"\n    ")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }