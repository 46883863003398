
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import IcSellerPro from '@/assets/svg/ic_seller_pro.svg?inline';
import IcSellerProAmbassador from '@/assets/svg/ic_seller_pro_ambassador.svg?inline';
import IcSellerSuperPro from '@/assets/svg/ic_seller_super_pro.svg?inline';
import { ShopServiceInterface } from '@/domain/shop/shop.service';
import { EBadgeSellerLevel, ShopStats } from '@/domain/shop/types';

export default Vue.extend({
  name: 'BadgeSellerLevel',
  components: {
    IcSellerPro,
    IcSellerProAmbassador,
    IcSellerSuperPro,
  },
  props: {
    badgeLevel: {
      type: Number as PropType<ShopStats['badgeLevel']>,
      default: null,
    },
    shouldEnableTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipId: 'badge-seller-level-tooltip',
    };
  },
  computed: {
    ariaDescribedBy(): string | null {
      return this.shouldEnableTooltip ? this.tooltipId : null;
    },
    badgeType(): ReturnType<ShopServiceInterface['getBadgeLevelTitle']> {
      return this.$services.shopService.getBadgeLevelTitle(this.badgeLevel || null);
    },
    badgeLabel(): TranslateResult | null {
      return this.getI18nBadgeLabel('title');
    },
    isPro(): boolean {
      return this.badgeType === EBadgeSellerLevel.Pro;
    },
    isSuperPro(): boolean {
      return this.badgeType === EBadgeSellerLevel.SuperPro;
    },
    isProAmbassador(): boolean {
      return this.badgeType === EBadgeSellerLevel.ProAmbassador;
    },
    shouldDisplayTooltip(): boolean {
      return this.shouldEnableTooltip && !!this.tooltipLabel;
    },
    tooltipLabel(): TranslateResult | null {
      return this.getI18nBadgeLabel('tooltip');
    },
  },
  methods: {
    getI18nBadgeLabel(i18nKey: string): TranslateResult | null {
      const i18nPath = `shop.badgeSeller.${this.badgeType}.${i18nKey}`;
      let label = null;

      if (this.$te(i18nPath)) {
        label = this.$t(i18nPath);
      } else {
        this.$errorMonitor.report(`I18n path "${i18nPath}" not resolved`, 'error');
      }

      return label;
    },
  },
});
