// Source: https://www.bqst.fr/country-code-to-flag-emoji/

export const getFlagEmoji = (countryCode: string): string | null => {
  if (!countryCode) {
    return null;
  }

  const codePoints = [...countryCode.toUpperCase()]?.map((char) => 127_397 + (char?.codePointAt(0) || 0));

  return String.fromCodePoint(...codePoints);
};
