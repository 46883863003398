
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CardText',
  props: {
    href: {
      type: String as PropType<string | null>,
      default: null,
    },
    isUserPro: {
      type: Boolean,
      default: false,
    },
    isSelencyProMessage: {
      type: Boolean,
      default: false,
    },
    subtitleI18nPath: {
      type: String,
      default: '',
    },
    titleI18nPath: {
      type: String,
      required: true,
    },
  },
});
