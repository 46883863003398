// https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/#combining-with-nuxtjs
import { Route, VueRouter } from 'vue-router/types/router';

import { AlgoliaRouter } from '@/infrastructure/externalServices/algolia/types';

export function _algoliaRouter(vueRouter: VueRouter): AlgoliaRouter {
  return {
    read(): Route['query'] {
      return vueRouter.currentRoute.query;
    },
    write(routeState): void {
      // Only push a new entry if the URL changed (avoid duplicated entries in the history)
      if (this.createURL(routeState) === this.createURL(this.read())) {
        return;
      }

      vueRouter.push({ query: routeState });
    },
    createURL(routeState): string {
      return vueRouter.resolve({ query: routeState }).href;
    },
    onUpdate(callBack) {
      if (typeof window === 'undefined') {
        return;
      }

      this._onPopState = () => {
        // https://github.com/algolia/vue-instantsearch/issues/954#issuecomment-829342439
        const query = { ...this.read(), hb: `${Date.now()}` };

        vueRouter.replace({ query });

        callBack(query);
      };

      window.addEventListener('popstate', this._onPopState);
    },
    dispose(): void {
      if (typeof window === 'undefined') {
        return;
      }

      if (this._onPopState) {
        window.removeEventListener('popstate', this._onPopState);
      }
    },
  };
}
