import { Product } from '@/domain/product/types';
import { UserPublic } from '@/domain/user/types';

export enum EOrderEntryReviewStatus {
  Automatic = 'automatic',
  Published = 'published',
  ToCollect = 'to_collect',
}

export interface OrderEntryReview {
  collectedAt: string;
  comment: string | null;
  id: string | null;
  product: Pick<Product, 'sku' | 'title' | 'mainPicture'>;
  rating: number; // NOTE: >= 1 && <= 5
  status: `${EOrderEntryReviewStatus}`;
}

export type Review = OrderEntryReview & { buyer: UserPublic };
export type ReviewToCollect = OrderEntryReview & { seller: UserPublic };
