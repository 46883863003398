var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('fieldset',{staticClass:"relative flex gap-3",class:[
    {
      'flex-col': !_vm.isInline,
      'text-gray-darker': _vm.isDisabled,
      'text-red': _vm.error,
    },
    _vm.cssSpacingClasses,
  ],attrs:{"data-testid":_vm.dataTestId,"disabled":_vm.isDisabled}},[_vm._l((_vm.options),function({ label, value, description },index){return _c('div',{key:value},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.modelPicked),expression:"modelPicked"}],staticClass:"absolute outline-none opacity-0 -left-[9999px]",attrs:{"id":_vm.buildUniqueRadioId({ id: _vm.inputId, index, value }),"name":_vm.inputName,"required":_vm.isRequired,"type":"radio"},domProps:{"value":value,"checked":_vm._q(_vm.modelPicked,value)},on:{"change":function($event){_vm.modelPicked=value}}}),_vm._v(" "),_c('label',{staticClass:"flex items-center",class:{
        'cursor-not-allowed': _vm.isDisabled,
        'cursor-pointer': !_vm.isDisabled,
      },attrs:{"for":_vm.buildUniqueRadioId({ id: _vm.inputId, index, value })}},[_c('span',{staticClass:"size-4 mt-0.5 mr-2 border rounded-full",class:{
          'bg-black border-black shadow-radio': _vm.picked === value,
          'bg-white border-gray-darker': _vm.picked !== value,
          'border-current': _vm.error || _vm.isDisabled,
        },attrs:{"data-testid":`${_vm.dataTestId}-dot`}}),_vm._v(" "),_c('span',{staticClass:"flex flex-col gap-0.5 select-none text-sm"},[_c('span',{attrs:{"data-testid":`${_vm.dataTestId}-label`}},[_vm._v("\n          "+_vm._s(label)+"\n        ")]),_vm._v(" "),(description)?_c('span',{class:{
            'text-current': _vm.error || _vm.isDisabled,
            'text-gray-darker': !_vm.error,
          },attrs:{"data-testid":`${_vm.dataTestId}-label-description`}},[_vm._v("\n          "+_vm._s(description)+"\n        ")]):_vm._e()])])])}),_vm._v(" "),_c('TransitionCollapse',[(_vm.error)?_c('p',{staticClass:"text-xs text-red",attrs:{"data-testid":`${_vm.dataTestId}-error`}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }