var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AisRefinementList',{attrs:{"attribute":_vm.EFacetNameRefinementList.ColorFacet,"class-names":{
    'ais-RefinementList-list': 'grid grid-cols-4 gap-4',
    'ais-RefinementList-item': 'flex mx-auto'
  },"limit":100,"sort-by":_vm.sortBy},scopedSlots:_vm._u([{key:"item",fn:function({ createURL, item, refine }){return [(_vm.isCrawlable)?_c('a',{staticClass:"sr-only",attrs:{"href":createURL(item.value)}},[_vm._v(_vm._s(_vm.getDisplayValueFromLabel(item.label)))]):_vm._e(),_vm._v(" "),_c('input',{staticClass:"input-hidden",attrs:{"id":item.value,"type":"checkbox"},domProps:{"checked":item.isRefined,"value":item.isRefined},on:{"change":function($event){return refine(item.value)}}}),_vm._v(" "),_c('label',{staticClass:"relative block m-px border-2 border-white rounded-full cursor-pointer size-12 outline outline-1",class:[_vm.getColorCssClassesFromLabel(item.label), {
        'outline-transparent hover:outline-black': !item.isRefined,
        'outline-black': item.isRefined,
      }],attrs:{"for":item.value,"title":_vm.getDisplayValueFromLabel(item.label)}},[(item.isRefined)?_c('span',{staticClass:"absolute flex items-center justify-center size-6 bg-white rounded-full top-2.5 left-2.5"},[_c('IcCheck',{staticClass:"size-6"})],1):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }