import { MetaInfo } from 'vue-meta';

import { EMetaTags, EOpenGraph, ETwitter } from '../../../domain/core/Meta.enum';

export interface MetaData {
  description: string;
  name: string;
  robots: string;
  title: string;
  url: string;
}

export const createGlobalMetaTags = ({
  name = 'Selency',
  robots = 'index, follow',
  url = 'https://www.selency.com',
}: Partial<MetaData> = {}): MetaInfo['meta'] => {
  const tags = [
    { name: EMetaTags.Author, content: name },
    { name: EMetaTags.Viewport, content: 'width=device-width, height=device-height, initial-scale=1.0, user-scalable=yes, minimum-scale=1.0' },
    { name: EMetaTags.Robots, content: robots },
    { name: EMetaTags.PinterestKey, content: `${process.env.PINTEREST_SITE_KEY}` },
    { name: ETwitter.Card, content: 'summary_large_image' },
    { name: ETwitter.Image, content: `${process.env.CDN_SELENCY_URL_IMAGES}/8c4c71d3-ed0c-413d-a11f-35f253b56998` },
    { name: ETwitter.Site, content: '@Selency_com' },
    { property: EOpenGraph.Image, content: `${process.env.CDN_SELENCY_URL_IMAGES}/8c4c71d3-ed0c-413d-a11f-35f253b56998` },
    { property: EOpenGraph.SiteName, content: name },
    { property: EOpenGraph.Type, content: 'website' },
    { property: EOpenGraph.Url, content: url },
  ];

  return tags.map((tag) => ({
    hid: tag.name || tag.property,
    ...tag,
  }));
};

export const createMetaTags = ({ description, title, image }: { description: string; image?: string; title: string }): MetaInfo['meta'] => {
  const imageTags = [];

  if (image) {
    imageTags.push(
      { name: ETwitter.Image, content: image },
      { property: EOpenGraph.Image, content: image },
    );
  }

  const tags = [
    { name: EMetaTags.Description, content: description },
    { name: ETwitter.Title, content: title },
    { name: ETwitter.Description, content: description },
    { name: EOpenGraph.Title, content: title },
    { name: EOpenGraph.Description, content: description },
    ...imageTags,
  ];

  return tags.map((tag) => ({
    hid: tag.name || tag.property,
    ...tag,
  }));
};
