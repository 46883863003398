/* istanbul ignore file */

// Source : https://github.com/algolia/vue-instantsearch/issues/936#issuecomment-964993901
// Algolia does not provide any types as of 2022-07 - disabling type checking for now.
// @ts-nocheck

// https://www.algolia.com/doc/guides/building-search-ui/going-further/server-side-rendering/vue/#with-nuxt

import { MultipleQueriesOptions, MultipleQueriesQuery } from '@algolia/client-search';
import { RequestOptions } from '@algolia/transporter';
import algoliasearch from 'algoliasearch/lite';
import Vue, { Component } from 'vue';
import { createServerRootMixin } from 'vue-instantsearch';
import _renderToString from 'vue-server-renderer/basic';

import { ECookie } from '@/domain/core/http/Cookie.enum';
import { _algoliaRouter } from '@/utilities/algolia/algoliaRouter';
import { _createStateMapping } from '@/utilities/algolia/stateMapping';

function $cloneComponent(componentInstance: any, { mixins = [] } = {}): Component {
  const options = {
    _base: undefined,
    fetch: undefined,
    i18n: componentInstance.$i18n,
    name: 'ais-ssr-root-component',
    router: componentInstance.$router,
    serverPrefetch: undefined,
    store: componentInstance.$store,
  };

  // https://github.com/algolia/vue-instantsearch/issues/1054
  // https://github.com/algolia/vue-instantsearch/pull/1104
  const Extended = componentInstance.$vnode
    ? componentInstance.$vnode.componentOptions.Ctor.extend(options)
    : Vue.component(
      options.name,
      Object.assign({}, componentInstance.$options, options),
    );

  const app = new Extended({
    propsData: componentInstance.$options.propsData,
    mixins: [...mixins],
  });

  app.$slots = componentInstance.$slots;
  app.$root = componentInstance.$root;
  app.$options.serverPrefetch = [];

  return app;
}

function renderToString(app: any): Promise<string> {
  return new Promise((resolve, reject) => {
    _renderToString(app, (err: string, response: any) => {
      if (err) {
        reject(err);
      }

      resolve(response);
    });
  });
}

export default Vue.extend({
  provide() {
    return {
      $_ais_ssrInstantSearchInstance: this.instantsearch,
    };
  },
  data() {
    let indexName = process.env.algoliaProductsIndexName;

    indexName = indexName.replace('{locale}', this.$i18n.locale.toUpperCase());

    const rawClient = algoliasearch(
      process.env.algoliaAppId,
      process.env.algoliaAppKey,
    );

    const searchClient = {
      ...rawClient,
      search: (queries: readonly MultipleQueriesQuery[], requestOptions?: RequestOptions & MultipleQueriesOptions) => {
        const userQueries = queries.map((query) => ({
          ...query,
          params: {
            ...query.params,
            userToken: this.$accessor.user.id || this.$cookies.get(ECookie.UserSegmentId),
          },
        }));

        return rawClient.search(userQueries, requestOptions);
      },
    };

    // eslint-disable-next-line vue/custom-event-name-casing
    this.$emit('init:index-name', indexName);

    const mixin = createServerRootMixin({
      searchClient,
      indexName,
      $cloneComponent,
      routing: {
        router: _algoliaRouter(this.$router),
        stateMapping: _createStateMapping(indexName, this.$route),
      },
      insights: true,
      future: {
        preserveSharedStateOnUnmount: true,
      },
    });

    return {
      ...mixin.data(),
    };
  },
  serverPrefetch() {
    return this.instantsearch
      .findResultsState({
        component: this,
        renderToString,
      })
      .then((algoliaState) => {
        this.$ssrContext.nuxt.algoliaState = algoliaState;
      });
  },
  watch: {
    '$route.query': {
      handler: async function(newValue) {
        if (newValue.hb) {
          const query = { ...newValue };

          delete query.hb;
          await this.$router.replace({ query });
          if (this.state?.instantSearchInstance?.helper) {
            this.state.instantSearchInstance.helper.search();
          }
        }
      },
    },
  },
  beforeMount() {
    const results = this.$nuxt.context?.nuxtState?.algoliaState || window.__NUXT__.algoliaState;

    this.instantsearch.hydrate(results);

    // Remove the SSR state so it can’t be applied again by mistake
    delete this.$nuxt.context.nuxtState.algoliaState;
    delete window.__NUXT__.algoliaState;
  },
});
