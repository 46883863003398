
import { connectSearchBox } from 'instantsearch.js/es/connectors';
import Vue from 'vue';
// @ts-ignore
import { createWidgetMixin } from 'vue-instantsearch';

// NOTE: Mount a virtual search box to manipulate InstantSearch's `query` UI state parameter.
const virtualSearchBox = createWidgetMixin({ connector: connectSearchBox });

export default Vue.extend({
  name: 'InstantSearchVirtualSearchBox',
  mixins: [virtualSearchBox],
});
