var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AisCurrentRefinements',{attrs:{"transform-items":_vm.transformItems},scopedSlots:_vm._u([{key:"default",fn:function({ items }){return [_c('ul',{staticClass:"flex gap-2 md:gap-1.5 flex-nowrap md:flex-wrap",class:{
        'py-4 md:pt-0': items?.length,
      }},[_c('li',{staticClass:"inline-block md:hidden"},[_c('InstantSearchClearRefinements')],1),_vm._v(" "),_vm._l((items),function(item,parentIndex){return _vm._l((item.refinements),function(refinement){return _c('li',{key:[
            parentIndex,
            refinement.attribute,
            refinement.operator,
            refinement.type,
            refinement.value,
          ].join(':'),staticClass:"inline-block"},[_c('div',{staticClass:"relative inline-flex items-center leading-none rounded-full bg-gray-light text-black text-tiny px-3.5 h-10"},[_c('span',{staticClass:"mb-0.5",class:{
                'block lowercase first-letter:uppercase': _vm.refinementAttributesToCapitalize.includes(refinement.attribute)
              }},[_vm._v(_vm._s(refinement.label))]),_vm._v(" "),_c('BaseButton',{staticClass:"static p-1 ml-2 rounded-full bg-gray-dark before:block before:w-full before:h-full before:absolute before:top-0 before:left-0",attrs:{"is-unstyled":""},on:{"click":function($event){return item.refine(refinement)}}},[_c('IcCrossThick',{staticClass:"text-white size-2"})],1)],1)])})})],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }