
import Vue, { PropType } from 'vue';

import { RadioOption, RadioUniqueId } from '@/infrastructure/core/components/input/Radio.interface';

export default Vue.extend({
  name: 'InputRadio',
  props: {
    cssSpacingClasses: {
      type: String,
      default: '',
    },
    dataTestId: {
      type: String,
      default: 'input-radio',
    },
    error: {
      type: String as PropType<string | null>,
      default: null,
    },
    inputId: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: '',
    },
    options: {
      type: Array as PropType<RadioOption[]>,
      required: true,
    },
    picked: {
      type: String,
      required: true,
    },
  },
  computed: {
    modelPicked: {
      get(): string {
        return this.picked;
      },
      set(value: boolean): void {
        this.$emit('update:picked', value);
      },
    },
  },
  methods: {
    buildUniqueRadioId({ id, value, index }: Partial<RadioUniqueId>): string {
      return `${id}-${value?.toLowerCase()}-${index}`;
    },
  },
});
