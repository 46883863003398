
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import IcStar from '@/assets/svg/ic_star.svg?inline';
import IcTag from '@/assets/svg/ic_tag.svg?inline';
import { ERouteName } from '@/domain/core/Routes.enum';
import { EShopType, ShopDetails, ShopStats } from '@/domain/shop/types';
import { EGtmEventProduct } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { getFlagEmoji } from '@/utilities/misc/getFlagEmoji';

export default Vue.extend({
  name: 'ProductShop',
  components: {
    IcTag,
    IcStar,
  },
  props: {
    cssClasses: {
      type: String,
      default: 'flex',
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
    shop: {
      type: Object as PropType<ShopDetails>,
      required: true,
    },
    shouldHideName: {
      type: Boolean,
      default: false,
    },
    shouldHideBadge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReviewsModalOpen: false,
    };
  },
  computed: {
    badgeLevel(): ShopStats['badgeLevel'] {
      return this.shop?.statistics?.badgeLevel || null;
    },
    hasBadge(): boolean {
      return !!this.badgeLevel;
    },
    isNewSeller(): boolean {
      return !this.rating && !this.productsSoldCount;
    },
    isProSeller(): boolean {
      return this.shop?.type === EShopType.Professional;
    },
    hrefToShopPage(): string | null {
      let link = null;

      if (this.shop?.legacyId) {
        link = this.$router.resolve({ name: ERouteName.ShopsId, params: { id: this.shop?.legacyId } })?.href;
      }

      return link;
    },
    newSellerLabel(): TranslateResult {
      const shopType = this.shop?.type || EShopType.Individual;

      return this.$t(`shop.newSeller.type.${shopType}`);
    },
    productsSoldCount(): number {
      return this.shop?.statistics?.productsSold || 0;
    },
    rating(): number | null {
      return this.shop?.statistics?.rating || null;
    },
    reviewsCount(): number {
      return this.shop?.statistics?.totalReviews || 0;
    },
    sellerName(): string | null {
      const { firstName = '', lastNameInitial = '' } = this.shop?.user || {};
      let name = null;

      if (firstName && lastNameInitial) {
        name = `${firstName} ${lastNameInitial}.`;
      }

      return name;
    },
    sellerFlag(): string | null {
      return getFlagEmoji(this.shop?.country);
    },
    shopId(): string | null {
      return this.shop?.id || null;
    },
    shouldDisplayBadge(): boolean {
      let shouldDisplayBadge = this.isProSeller
        && !this.isNewSeller // UI safe guard : this should *theoretically* never happen.
        && this.hasBadge;

      if (this.shouldHideBadge) {
        shouldDisplayBadge = !this.shouldHideBadge;
      }

      return shouldDisplayBadge;
    },
    shouldDisplayHrefToShopPage(): boolean {
      const isCurrentPageShop = this.$route?.path === this.hrefToShopPage;
      let shouldBeDisplayed = !!this.hrefToShopPage && !this.isCompact;

      if (isCurrentPageShop) {
        shouldBeDisplayed = !isCurrentPageShop;
      }

      return shouldBeDisplayed;
    },
    shouldDisplayName(): boolean {
      const isCompactAndHasBadge = this.isCompact && this.shouldDisplayBadge;
      let shouldBeDisplayed = !!this.sellerName && !isCompactAndHasBadge; // Hidden if component is compact AND there is a badge.

      if (this.shouldHideName) {
        shouldBeDisplayed = !this.shouldHideName;
      }

      return shouldBeDisplayed;
    },
    shouldDisplaySalesCount(): boolean {
      const isExpandedOrHasNoRating = !this.isCompact || !this.rating;

      return !!this.productsSoldCount
        && isExpandedOrHasNoRating;
    },
  },
  methods: {
    openReviewsModal(): void {
      this.isReviewsModalOpen = true;
    },
    closeReviewsModal(): void {
      this.isReviewsModalOpen = false;
    },
    handleTracking(): void {
      this.$gtm.push({ event: EGtmEventProduct.LinkToSellerShop });
    },
  },
});
