
import Vue from 'vue';

import { ESidebarComponentId } from '@/domain/core/a11y/SidebarComponentId.enum';

const baseI18nPath = 'page.search.randomInserts';

interface CardTextProps {
  href?: string | null;
  subtitleI18nPath: string;
  titleI18nPath: string;
}

export default Vue.extend({
  name: 'CardTextRandom',
  props: {
    isUserPro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      FormLogin: {
        component: () => import('@/components/organisms/form/FormLogin.vue'),
        id: ESidebarComponentId.FormLogin,
      },
      randomIndex: null as number | null,
    };
  },
  computed: {
    componentType(): string {
      return this.isSelencyProMessage ? 'SidebarToggle' : 'p';
    },
    baseI18nPath(): string {
      return this.isUserPro
        ? `${baseI18nPath}.proUsers`
        : `${baseI18nPath}.productList.${this.randomIndex}`;
    },
    contentProps(): CardTextProps {
      const props: CardTextProps = {
        subtitleI18nPath: `${this.baseI18nPath}.subtitle`,
        titleI18nPath: `${this.baseI18nPath}.title`,
      };

      if (this.$te(`${this.baseI18nPath}.internalLinkId`)) {
        props.href = this.$externalURLRouter.getPathFromRoute(this.$t(`${this.baseI18nPath}.internalLinkId`) as string);
      }

      if (this.$te(`${this.baseI18nPath}.routeName`)) {
        props.href = this.$router.resolve({ name: this.$t(`${this.baseI18nPath}.routeName`) as string }).href;
      }

      return props;
    },
    // Text insert displayed to normal users to encourage them to subscribe to Selency Pro
    isSelencyProMessage(): boolean {
      const selencyProMessageIndex = this.mainI18nKeyItemsCount - 1;

      return !this.isUserPro && this.randomIndex === selencyProMessageIndex;
    },
    mainI18nKeyItemsCount(): number {
      const items: unknown = this.$t(`${baseI18nPath}.productList`);

      return (items as string[]).length;
    },
  },
  mounted() {
    this.generateRandomI18nKeyIndex();
  },
  methods: {
    generateRandomI18nKeyIndex(): void {
      this.randomIndex = Math.floor(Math.random() * this.mainI18nKeyItemsCount);
    },
  },
});
